/* ----------------------------------------------------------------------------------------------------- */

/*  @ Import/write your custom styles here.
/* ----------------------------------------------------------------------------------------------------- */

:root {
  --standard-stone: #f1ece9;
  --rebac-red: #f8422e;
  --raw-melon: #fc7d69;
  --salmon-blush: #ffb8a4;
  --loyal-blue: #0f154d;
  --dirty-dove: #2c4e8c;
  --dusty-dolphin: #a7addb;
}

.gradient-font-color {
  background: linear-gradient(
    135deg,
    var(--loyal-blue),
    var(--dirty-dove),
    var(--dusty-dolphin),
    var(--rebac-red)
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.rebac-radial-background {
  background: radial-gradient(
      50vw 80vh at right 0 top 0,
      var(--raw-melon) 35%,
      transparent
    ),
    radial-gradient(50vw 100vh at top, var(--dusty-dolphin) 10%, transparent);
}
